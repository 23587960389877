import knob from '../../public/icon/knob.svg';
import glass from '../../public/icon/glass.svg';
import paper from '../../public/icon/paper.svg';
import plastic from '../../public/icon/plastic.svg';
import different from '../../public/icon/different.svg';

export const loadGarbageIcons = (garbage, type) => {
  const garbageTypes = [
    {
      id: 1,
      icon: knob,
      title: 'Бумага',
      visible: false
    },
    {
      id: 2,
      icon: glass,
      title: 'Стекло',
      visible: false
    },
    {
      id: 3,
      icon: paper,
      title: 'Металл',
      visible: false
    },
    {
      id: 4,
      icon: plastic,
      title: 'PET пластик',
      visible: false
    },
    {
      id: 5,
      icon: different,
      title: 'Другое',
      visible: false
    }
  ];

  if (garbage !== null && type === 5) {
    const garbageItem = garbage?.garbageType?.split(',');
    const visibleIcons = [];

    garbageItem?.forEach(element => {
      const findItem = garbageTypes.find(item => item.title === element);
      if (findItem) {
        findItem.visible = true;
        visibleIcons.push(findItem);
      }
      /* if (garbageTypes[index].title === element) {
        garbageTypes[index].visible = true
        visibleIcons.push(garbageTypes[index])
      } */
    });
    return {
      flag: true,
      icons: garbageTypes,
      visibleIcons
    };
  }
  return {
    flag: false,
    icons: garbageTypes
  };
};
