<template>
  <div>
    <a
      :href="
        this.$router.resolve({
          name: 'info',
          params: { id: item.id },
        }).href
      "
    >
      <div class="card">
        <div class="card__info">
          <p
            class="roquefort"
            v-if="!!type"
          >{{ type }}</p>
          <p
            class="mt-1 сaprino"
            v-if="!!address"
          >
            {{ address }}
          </p>
          <garbage-icons
            :data="item.extraData"
            :type="typeId"
            :classes="'obj-card__counters flex mozzarella style-marin-house mt-3'"
          />
          <!--          <div class="obj-card__counters flex mozzarella style-marin-house mt-1" v-if="typeId === 5">-->
          <!--            Информация о пунктах приема собрана в рамках проекта «Чистый город начинается с тебя»-->
          <!--          </div>-->
          <div class="mt-3 mb-3">
            <div>
              <div class="flex">
                <r-icon
                  icon="clock"
                  size="16"
                  fill="rocky"
                />
                <div class="briscola clock ml-2">
                  {{
                    item.schedule
                      ? item.schedule
                      : item.extraData
                        ? `${item.extraData.days} ${item.extraData.clock}`
                        : '-'
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import GarbageIcons from './GarbageIcons';

export default {
  name: 'BalloonCard',
  components: {
    GarbageIcons
  },
  props: {
    type: {
      type: String
    },
    address: {
      type: String
    },
    id: {
      type: String
    },
    item: {
      type: Object
    },
    typeId: {
      type: Number
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  width: 200px;
  max-width: 300px;
  &__img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }
  &__info {
    padding: 8px 16px 1px 16px;
    word-wrap: break-word;
  }
}
</style>
<style>
.roquefort {
  font-size: 13px;
  line-height: 16px;
}
.сaprino {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
}

.leaflet-container a.leaflet-popup-close-button {
  display: none;
}

.leaflet-container a {
  color: unset !important;
}

.leaflet-popup-tip-container {
  display: none;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  margin-bottom: 20px;
}

.leaflet-popup-content {
  margin: 0px 0px 0px 0px !important;
}

.leaflet-popup-content-wrapper {
  padding: 0px !important;
}

.leaflet-popup-content p {
  margin: 4px 0 !important;
}
</style>
