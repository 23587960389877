<template>
  <div>
    <div v-if="isLoading" />
    <tag-carousel
      v-else
      :items="$store.state.garbageTypes"
      @select="onChangeType"
    />
    <loader v-show="isLoading" />
    <div
      v-show="!isLoading"
      class="culture mt-8"
      :key="count"
    >
      <div
        class="culture__cards"
        v-if="!!objects ? objects.length > 0 : false"
      >
        <router-link
          v-for="item in objects ? objects : []"
          :key="item.id"
          :to="{
            name: 'info',
            params: { id: item.id },
          }"
        >
          <object-card
            :type="item.type"
            :address="item.address"
            :extra-data="item.extraData"
            :schedule="item.schedule"
          />
        </router-link>
      </div>
      <div
        class="culture__cards"
        v-else
      >
        <h3>Ничего не найдено</h3>
      </div>
      <div class="culture__map-cont">
        <map-culture
          :search-in="true"
          :model-search="searchText"
          @update:modelSearch="newValue => (searchText = newValue)"
          :garbage="hideGrabageTrucks == true ? false : true"
          :objects="objects"
          :balloons="true"
          :component-key="componentKey"
          :coordinates="coordinates"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ObjectCard from '@/components/ObjectCard.vue';
import TagCarousel from '../components/TagCarousel.vue';
import MapCulture from '../components/MapCulture';
import MapSearch from '../components/MapSearch';
import Loader from '@/components/Loader.vue';
import L from 'leaflet';
import PlaceApi from '@/api/PlaceApi';

export default {
  name: 'ObjectList',
  components: {
    Loader,
    ObjectCard,
    TagCarousel,
    MapCulture,
    MapSearch
  },

  data() {
    return {
      objectTypes: [
        {
          id: 0,
          title: 'Все',
          active: true
        }
      ],
      hideGrabageTrucks: false,
      count: 0,
      typeId: 0,
      isMapCollapsed: false,
      searchText: null,
      timeout: null,
      componentKey: 0,
      coordinates: null
    };
  },

  computed: {
    allObjects() {
      return this.$store.state.objects;
    },
    objects() {
      if (this.allObjects === null) {
        return []; // Return empty array if allObjects is null
      }
      let objects = [];
      let filteredObjects = this.allObjects;

        objects = filteredObjects.map(el => ({
          id: String(el.id),
          address: String(el.address),
          type: parseInt(el.type),
          FillingPercentage: el.FillingPercentage || ' - ',
          updated: el.updated || ' - ',
          camera_guid: el.camera_guid,
          icon: this.$markerIcon.imageHref,
          extraData: {
            ...el.extraData,
            clock: el.extraData?.clock || '00:00 - 00:00',
            days: el.extraData?.days || ''
          },
          schedule: el.schedule || '00:00 - 00:00',
          lat: el.lat,
          lng: el.lng
        }));

        if (this.typeId !== 0) {
          objects = objects.filter(el => Number(el.type) === this.typeId);
        }

        if (this.searchText) {
          filteredObjects = objects.filter(el => this.onAddress(el.address));
          this.componentKey++;

          if (filteredObjects[0]) {
            this.coordinates = [filteredObjects[0].lat, filteredObjects[0].lng];
          }

          if (filteredObjects.length === 0) {
            this.onCoordinates(this.searchText);
          }
        return filteredObjects;
      }
      this.coordinates = objects[0] ? [objects[0].lat, objects[0].lng] : this.$cityCenter;

      this.componentKey++;

      return objects;
    },
    isLoading() {
      return this.$store.state.isObjectLoading;
    }
  },
  watch: {
    isCollapsed() {
      if (!this.isCollapsed) {
        this.$refs.map.querySelector('.ymaps-2-1-79-float-button-icon_icon_expand').click();
      } else {
        document.querySelector('.ymaps-2-1-79-float-button-icon_icon_collapse').click();
      }
    }
  },
  watch: {
    isCollapsed() {
      if (!this.isCollapsed) {
        this.$refs.map.querySelector('.ymaps-2-1-79-float-button-icon_icon_expand').click();
      } else {
        document.querySelector('.ymaps-2-1-79-float-button-icon_icon_collapse').click();
      }
    }
  },

  async created() {
    const response = await new PlaceApi().systemOptions();
    this.hideGrabageTrucks = response?.ntechlabConfig?.hideGarbageTrucks;
  },

  methods: {
    onCoordinates(item) {
      if (item) {
        axios
          .get(
            `https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=${this.$cityCenter} ${item.trim()}&format=json&lang=ru_RU&kind=house`
          )
          .then(result => {
            const geo = result.data.response.GeoObjectCollection.featureMember?.[0]?.GeoObject;

            if (geo) {
              this.coordinates = geo.Point?.pos?.split(' ').reverse();
              console.log(this.coordinates);
            } else {
              console.log('ппц');
            }
          });
      }
    },
    onAddress(address) {
      let len = 0;
      const arr = this.searchText
        .toLowerCase()
        .replace(/ +/g, ' ')
        .trim()
        .split(' ');
      arr.forEach(item => {
        if (address.toLowerCase().indexOf(item) >= 0) {
          len++;
        }
      });
      return address ? len === arr.length : false;
    },
    onChangeType(type) {
      this.typeId = type.id;
    }
  }
};
</script>
<style lang="scss" scoped>
.culture {
  display: flex;
  justify-content: space-between;

  &__cards {
    width: 41.7%;
  }
  &__map-cont {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    height: 648px;
    width: calc(58.3% - 32px);
    margin-left: 32px;
  }
}

.balloon-staff {
  display: grid;
  grid-template-columns: auto 1fr;
}

@media screen and (max-width: 1023px) {
  .culture__map-cont {
    position: relative;
    top: 0;
    width: 100%;
    margin-left: 0px;
    height: auto;
    margin-bottom: 20px;
  }
  .culture {
    max-width: 100%;
    flex-direction: column-reverse;

    &__cards {
      width: 100%;
    }
  }
}
@media screen and (min-width: 600px) {
  .culture {
    &__input-search {
      position: absolute;
      z-index: 10;
      background-color: #fff;
      top: 16px;
      left: 16px;
    }
  }
}
</style>
