<template>
  <div>
    <router-link
      class="flex align-center sulguni mb-7 print"
      :to="{ name: 'admin' }"
    >
      <r-icon
        class="mr-2"
        icon="arrow-left"
      />
      <div style="margin-top: -2px">
        Назад
      </div>
    </router-link>
    <div class="card_hidden">
      <div class="width">
        <div>
          <h2>{{ typeName }}</h2>
        </div>
        <!-- <div style="float: left" class="ml-3">
           <rir-icon class="mr-4 mt-2" icon="favorite-add" size="20"/>
         </div>
         -->
      </div>
      <div class="mt-1">
        {{ items.address }}
      </div>
    </div>
    <loader v-if="isLoading" />
    <div
      v-else
      :key="countInput"
      class="culture mt-8"
    >
      <div class="culture__cards">
        <div class="card_hidden_down">
          <div class="width">
            <div>
              <h2>{{ typeName }}</h2>
            </div>
          </div>
          <div class="mt-1">
            {{ items.address }}
          </div>
        </div>
        <div v-if="items.type === 5">
          <div class="mt-8 opacity">
            Прием видов ТКО
          </div>
          <garbage-icons
            :data="items.extraData"
            :type="Number(items.type)"
            :classes="'mt-1 align-center sulguni flex mozzarella style-marin-house'"
          />
        </div>
        <div class="mt-8 opacity">
          Объем одного контейнера м3
        </div>
        <div class="mt-1">
          {{ items.volume !== null ? items.volume || 0 : 0 }}
        </div>
        <div class="mt-8 opacity">
          Количество контейнеров
        </div>
        <div class="mt-1">
          {{ items.amount !== null ? items.amount || 0 : 0 }}
        </div>
        <div class="mt-5 opacity">
          Материал покрытия площадки
        </div>
        <div class="mt-1">
          {{ items.coverage !== null ? items.coverage || ' - ' : ' - ' }}
        </div>
        <div class="mt-8 opacity">
          Плошадь площадки м2
        </div>
        <div class="mt-1">
          {{ items.square !== null ? items.square || 0 : 0 }}
        </div>
        <div class="mt-8 opacity">
          Источник образования ТКО
        </div>
        <div class="mt-1">
          {{
            items.owner_address !== null
              ? `${items.property_type} ${items.property_address}` || ' - '
              : ' - '
          }}
        </div>
        <div class="mt-8 opacity">
          Владеющая организация
        </div>
        <div class="mt-1 mb-8">
          {{ items.owner !== null ? items.owner || 0 : 0 }}
        </div>
        <div
          class="flex mr-5 mb-8"
          v-if="items.extraData !== null"
        >
          <r-icon
            class="mr-2"
            icon="clock"
            fill="rocky"
          />
          <span class="briscola clock">
            {{
              (items.extraData.another
                ? items.extraData.another
                : `${items.extraData.days} ${items.schedule}` ? items.schedule : items.extraData.clock) || ' - '
            }}
          </span>
        </div>
        <div
          class="flex mr-5 mb-8"
          v-else-if="!!items.schedule"
        >
          <r-icon
            class="mr-2"
            icon="clock"
            fill="rocky"
          />
          <span class="briscola clock">
            {{ items.schedule }}
          </span>
        </div>
        <rating
          class="mt-8"
          title="Оцените качество содержания площадки ТКО"
          :val="userRating"
          :avg-rating="items.rating ? items.rating.substr(0, 3) : null"
          @change="setRating"
        />
      </div>
      <div class="culture__map-cont">
        <map-culture
          :garbage="false"
          :search-in="false"
          :coordinates="[Number(items.lat), Number(items.lng)]"
          :type="Number(items.type)"
          :component-key="count"
          :zoom="18"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MapCulture from '../components/MapCulture';
import GarbageIcons from '../components/GarbageIcons';
import Loader from '@/components/Loader.vue';
import Rating from '../components/Rating';
import PlaceApi from '../api/PlaceApi';

export default {
  components: {
    Loader,
    MapCulture,
    GarbageIcons,
    Rating
  },
  props: {},
  data() {
    return {
      transportData: {
        anims: []
      },
      items: {},
      isLoad: false,
      coordinates: [],
      timeTo: '0:00',
      timeFrom: '0:00',
      count: 0,
      countInput: 0,
      itemsCount: null,
      sort: [],
      typeName: '',
      isMapCollapsed: false,
      publicPath: process.env.BASE_URL,
      mapIcons: [this.$markerIcon, this.$tko, this.$wasteBin, this.$wasteCam, this.$separate],
      userRating: 0
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.isObjectLoading;
    }
  },
  async created() {
    await this.$store.dispatch('loadId', this.$route.params.id).then(
      result => {
        console.log(result);
        const keys = Object.keys(this.$store.state.loadId.all[0]);
        this.coordinates = [
          Number(this.$store.state.loadId.all[0].lat),
          Number(this.$store.state.loadId.all[0].lng)
        ];
        console.log(this.coordinates);
        this.items = {
          ...this.$store.state.loadId.all[0],
          extraData: {
            ...this.items.extraData,
            clock: this.items.extraData?.clock ? this.items.extraData?.clock : '00:00 - 00:00',
            days: this.items.extraData?.days ? this.items.extraData?.days : ''
          }
        };
        // keys.forEach(key => {
        //   this.items[key] = this.$store.state.loadId.all[0][key];
        // });

        if (this.items.schedule !== null) {
          if (this.items.schedule !== '') {
            const garbageItem = this.items.schedule.split(' - ');
            this.timeTo = garbageItem[0];
            this.timeFrom = garbageItem[1];
          }
        }
        const objectType = ['Все', 'Контейнер', 'Площадка ТКО', 'Мусорокамера', 'Раздельный сбор'];
        const keySelect = Object.keys(objectType);
        keySelect.forEach(key => {
          this.sort[key] = {
            value: objectType[key],
            id: key,
            icon: true
          };
        });
        this.itemsCount = this.items.amount;
        this.typeName = this.sort[this.items.type]?.value || 'ТКО';
        this.countInput++;
        this.count++;
      },
      error => {
        console.error(error);
      }
    );
    const user = await new PlaceApi().getUserInfo();
    if (user?.userId) {
      await new PlaceApi().getUserRating(user.userId).then(res => {
        this.userRating = res.all.find(el => el.itemId === this.$route.params.id)?.rating || 0;
      });
    }
  },
  methods: {
    async setRating(rating) {
      const currentMainMenuNumber = '51';
      const payload = JSON.stringify({
        id: this.$route.params.id,
        currentMainMenuNumber,
        rating
      });
      this.userRating = rating;
      await new PlaceApi().setRating(payload);
    }
  }
};
</script>

<style lang="scss" scoped>
.card_hidden {
  display: none;
}

.card_hidden_down {
  display: table;
}

.opacity {
  color: #04153e;
  opacity: 0.48;
}

.print {
  fill: #3d75e4;
  color: #3d75e4;
}

.width {
  display: table;
  width: 100%;
}

.culture {
  display: flex;
  justify-content: space-between;

  &__cards {
    width: 41.7%;
  }

  &__map-cont {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    height: 648px;
    width: calc(58.3% - 32px);
    margin-left: 32px;
  }
}

.balloon-staff {
  display: grid;
  grid-template-columns: auto 1fr;
}

@media screen and (max-width: 1023px) {
  .card_hidden {
    display: table;
  }
  .card_hidden_down {
    display: none;
  }
  .culture {
    max-width: 100%;
    flex-direction: column-reverse;

    &__cards {
      width: 100%;
    }

    &__map-cont {
      position: relative;
      top: unset;
      background-color: #fff;
      height: unset;
      padding: 0;
      margin-left: unset;
      width: 100%;
    }
  }
}

@media screen and (max-width: 599px) {
  .card_hidden_down {
    display: none;
  }
  .card_hidden {
    display: table;
  }
}
</style>
