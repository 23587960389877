import Vue from 'vue';
import Vuex from 'vuex';
import PlaceApi from '@/api/PlaceApi';

const api = new PlaceApi();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    types: null,
    objects: null,
    isObjectLoading: null,
    isHasObjects: false,
    loadId: null,
    garbageTypes: [
      {
        title: 'Все',
        icon: null,
        id: 0,
        active: true
      }
    ]
  },

  mutations: {
    setGarbageTypes(state, types) {
      if (Array.isArray(types)) {
        state.garbageTypes = [
          ...state.garbageTypes,
          ...types.filter(el => el.isHidden === false).map(el => ({
            ...el,
            active: false
          }))
        ];
      }
    },
    setObjects(state, objects) {
      state.objects = objects.all.filter(
        el => el.lat
          && el.lng
          && el.hidden !== '1'
      );
    },
    setLoadId(state, objects) {
      state.loadId = objects;
    },
    setObjectLoading(state, status) {
      state.isObjectLoading = status;
      status && (state.isHasObjects = true);
    }
  },
  getters: {
    getType: state => type => state.garbageTypes.find(garbageType => garbageType.id === type) || {
      title: '', // Ошибка выбора типа
      icon: '', // warning
      id: null
    },
    getList: () => state.objects,
    getLoadId: () => state.objects
  },
  actions: {
    async loadObjects(ctx, typeId = 0) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getList();
      ctx.commit('setObjects', res);
      ctx.commit('setGarbageTypes', res.tkoTypes);
      ctx.commit('setObjectLoading', false);
    },
    async loadId(ctx, id) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getLoadId(id);
      ctx.commit('setLoadId', res);
      ctx.commit('setObjectLoading', false);
      return res;
    }
  },
  modules: {}
});
