import ApiService from './ApiService';

export default class PlaceApi extends ApiService {
  // - справочник типов клиник, ФИО для дропдауна, координаты для карты

  async getGarbageTypes() {
    const { data } = await this._axios.get('/ajax.php?action=getCityFastOptions&json={"fields":["ntechlabConfig"]}');

    return data;
  }
  async systemOptions() {
    const { data } = await this._axios.post('/ajax.php?action=getCityFastOptions&json={"fields":["ntechlabConfig"]}');

    return data;
  }
  async getList(payload) {
    const { data } = await this._axios.post('/ajax.php?action=getGarbage', payload);
    return data;
  }
  async getLoadId(id) {
    const { data } = await this._axios.get(`/ajax.php?action=getGarbage&id=${id}`);
    return data;
  }
  async getUserInfo() {
    const { data } = await this._axios.get('/nginxApi.php?get=user');
    return data;
  }
  async getUserRating(userId){
    const { data } = await this._axios.get(`/ajax.php?action=getPublicRating&currentMainMenuNumber=51&userId=${userId}`);
    return data;
  }
  async setRating(payload) {
    const { data } = await this._axios.post('/ajax.php?action=operatePublicRating', payload);
    return data;
  }
}
