<template>
  <div
    class="card"
  >
    <r-icon
      class="mr-4"
      :icon="getType(type).icon || ''"
      fill="rocky"
      size="32"
    />

    <div class="card__info">
      <h3 class="card__title">
        {{ getType(type).title || '' }}
      </h3>
      <p
        class="card__address"
        v-if="address"
      >
        {{ address }}
      </p>

      <garbage-icons
        v-if="type === 5"
        :data="extraData"
        :type="type"
        :classes="'obj-card__counters flex obj-card__address mozzarella style-marin-house mt-3'"
      />
      <div class="align-center sulguni flex mozzarella style-marin-house mt-3">
        <r-icon
          class="mr-1 float"
          icon="clock"
          size="16"
          fill="rocky"
        />
        <span class="briscola clock">
          {{ schedule ? schedule : extraData ? `${extraData.days} ${extraData.clock}` : '-' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import GarbageIcons from './GarbageIcons';
import { mapGetters } from 'vuex';
export default {
  components: {
    GarbageIcons
  },
  props: ['type', 'typeName', 'address', 'extraData', 'schedule'],
  data() {
    return {
      icons: {
        1: 'tko',
        2: 'tko-place',
        3: 'waste-bin',
        4: 'waste-camera',
        5: 'separate',
        6: 'separate'
      }
    };
  },
  computed:{
    ...mapGetters(['getType']),
  }
};
</script>

<style lang="scss" scoped>
.clock {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153e;
}

.card {
  margin-bottom: 32px;
  display: flex;
  align-items: flex-start;

  .style-marin-house {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #04153e;
  }

  &__img-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 56px;
    min-height: 56px;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    margin-right: 16px;
    background-color: var(--rir-arrival);
    overflow: hidden;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: left;
    overflow: hidden;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--rir-titanic);
  }

  &__address {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: var(--rir-titanic);
    opacity: 0.72;
  }

  &__additional {
    margin-top: 12px;
    display: flex;
    align-items: center;
  }

  &__additional-info > * {
    margin-left: 6px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: var(--rir-rocky);
  }

}
</style>
